<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }" style="min-height: 200px;">
    <div v-bind:class="{'hidden': !isLoaded }">

      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <div class="form-group">
            <label class="placeholder placeholder-inactive" for="client_company_name">Client name</label>
            <input
              :class="['form-control', {'error': v$.clientCompanyName.$error}]"
              placeholder="Name"
              type="text"
              name="client_company[name]"
              id="client_company_name"
              v-model.trim="clientCompanyName"
              @keydown.enter.prevent="submitForm"
            />
            <div v-if="v$.clientCompanyName.$error" class="error">Client name is required</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <div class="form-group">
            <label class="placeholder placeholder-static" for="client_company_description">Client description</label>
            <textarea 
              rows="4" 
              class="form-control" 
              placeholder="Description" 
              name="client_company[description]" 
              id="client_company_description"
              v-model.trim="clientCompanyDescription"
            ></textarea>
          </div>
        </div>
      </div>
            
      <div class="form-group">
        <form-button
          ref="submitButton"
          :label="submitButtonLabel"
          @submit="submitForm"
        ></form-button>
      </div>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import formButton from "../shared/form/form_button";

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  components: {
    formButton
  },

  props: {
    formId: String,
    currentClientCompanyId: String,
    currentClientCompanyName: String,
    currentClientCompanyDescription: String
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      isLoaded: false,
      isSpinnerVisible: true,
      clientCompanyName: "",
      clientCompanyDescription: ""
    }
  },

  validations () {
    return {
      clientCompanyName: {
        required
      }
    }
  },

  created: function() {
    this.clientCompanyName = this.currentClientCompanyName;
    this.clientCompanyDescription = this.currentClientCompanyDescription;
  },

  mounted: function() {
    this.isLoaded = true;

    setTimeout(() => {
      this.isSpinnerVisible = false;
    }, 1000);
  },

  computed: {
    submitButtonLabel: function() {
      return (this.currentClientCompanyId) ? "Update Client Company" : "Create Client Company";
    }
  },

  methods: {
    submitForm: function() {
      const form = document.forms[this.formId];

      this.v$.clientCompanyName.$touch();

      if (form && this.v$.$errors.length <= 0) {
        this.$refs.submitButton.loadingOn();

        form.submit();
      }
    }
  }
}

</script>