<template>
  <div :class="['findings-filter', {'spinner-is-visible': isSpinnerVisible }]">
    <div :class="{'hidden': !isLoaded }">
      
      <fieldset>
        <div class="row">
          <div class="col-sm-12 col-xs-12">
            <div class="form-group">
              <div class="form-control-toggle">
                <input 
                  type="checkbox" 
                  id="chkCurrentState"
                  :class="{'checked': !filter.historical }"
                  :checked="!filter.historical"
                  @change="setHistorical">
                <label for="chkCurrentState">
                  <span>Filter current state only</span><span></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div 
          v-if="isExoUser"
          class="row">
          <div class="col-sm-12 col-xs-12">
            <div class="form-group pt-0">
              <div class="form-control-toggle toggle-warning">
                <input 
                  type="checkbox" 
                  id="chkFilterPrevievMode"
                  :class="{'checked': isInPrevievMode }"
                  :checked="isInPrevievMode"
                  @change="setPreviewMode">
                <label for="chkFilterPrevievMode">
                  <span>Filter in preview mode</span><span></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-xs-12">
            <div class="form-group pt-0">
              <div class="form-control-toggle">
                <input 
                  type="checkbox" 
                  id="chkRemediatedFindings"
                  :class="{'checked': filter.hasRemediation }"
                  :checked="filter.hasRemediation"
                  @change="setRemediatedFindings">
                <label for="chkRemediatedFindings">
                  <span>Filter remediated findings only</span><span></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <legend>Filter options</legend>
        <div class="row" style="padding-bottom: 5px">
          <div class="col-sm-12 col-xs-12">
            <damage-classification
              v-if="isLoaded"
              ref="ratingClassification"
              placeholder="Rating"
              name="finding[classification]"
              id="finding_classification"
              :default-rating="defaultFilter.clientRemediatedRating || defaultFilter.remediationPreviewRating || []"
              :ratings="ratings"
              @changeRating="setRating"
            ></damage-classification>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-xs-12">
            <div class="form-group">
              <label class="placeholder placeholder-inactive">Projects</label>
              <v-select
                multiple
                placeholder="Select projects"
                ref="projects"
                :disabled="!filter.historical"
                :options="projects"
                label="name"
                @option:selected="selectProject"
                @option:deselected="deselectProject"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-xs-12">
            <div class="form-group">
              <label class="placeholder">Asset Types</label>
              <v-select
                multiple
                placeholder="Select assets types"
                ref="physicalAssetTypes"
                :options="physicalAssetTypes"
                label="name"
                @option:selected="selectPhysicalAssetType"
                @option:deselected="deselectPhysicalAssetType"
              >
              </v-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-xs-12">
            <div class="form-group">
              <label class="placeholder placeholder-inactive">Components</label>
              <v-select
                multiple
                placeholder="Select components"
                ref="componentTypes"
                :options="componentTypes"
                label="name"
                @option:selected="selectComponentType"
                @option:deselected="deselectComponentType"
              />
            </div>
          </div>
        </div>
        <div
          v-if="showFindingDetailsFilter"
          class="row"
        >
          <div class="col-sm-12 col-xs-12">
            <div class="form-group">
              <label class="placeholder placeholder-inactive">Finding Types</label>
              <v-select
                multiple
                placeholder="Select finding types"
                ref="findingTypes"
                :options="findingTypes"
                label="name"
                @option:selected="selectFindingType"
                @option:deselected="deselectFindingType"
              />
            </div>
          </div>
        </div>
        <div
          v-if="showFindingDetailsFilter"
          class="row"
        >
          <div class="col-sm-12 col-xs-12">
            <div class="form-group">
              <label class="placeholder placeholder-inactive">Remediation Types</label>
              <v-select
                multiple
                placeholder="Select remediation types"
                ref="remediationTypes"
                :options="remediationTypes"
                label="name"
                @option:selected="selectRemediationType"
                @option:deselected="deselectRemediationType"
              >
              </v-select>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset v-if="isDetailsVisible">
        <legend>Finding details</legend>
        <field-multiselect
          v-for="(item, index) in findingDetails"
          v-bind="item"
          :key="item.name"
          :index="index"
          :id="item.name"
          :name="item.name"
          :placeholder="item.name"
          :options="item.options"
          :value="findingsDetailsDefaultValues(item)"
          @selected="selectFindingTypeDetails"
          @deselected="deselectFindingTypeDetails"
        ></field-multiselect>
      </fieldset>

      <div style="display: flex; justify-content: end;">
        <ActionButton
          :css="isFilterEmpty ? 'disabled' : ''"
          text="Clear Filter"
          title="Reset Findings Filter"
          icon="autorenew"
          size="md"
          action="default"
          @clickAction="clearFilter"
        />
      </div>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import FieldMultiselect from "../form/field_multiselect";
import DamageClassification from "./damage_classification";
import ActionButton from "../action_button";
import Filter from "./findings_filter_core";

export default {
  extends: Filter,

  components: {
    vSelect,
    DamageClassification,
    ActionButton,
    FieldMultiselect
  },

  props: {
    isExoUser: Boolean,
    siteId: String,
    showFindingDetailsFilter: Boolean
  },

  data() {
    return {
      defaultFilter: {},

      filter: {
        historical: false,
        hasRemediation: false,
        projectId: [],
        clientRemediatedRating: [],
        remediationPreviewRating: [],
        siteId: [],
        physicalAssetTypeId: [],
        componentTypeId: [],
        findingTypeId: [],
        remediationTypeId: [],
        findingDetails: {},
        status: ['approved']
      },

      ratings: [0, 1, 2, 3, 4, 5],
      projects: this.projects,
      components: this.components,
      physicalAssetTypes: this.physicalAssetTypes,
      componentTypes: this.componentTypes,
      findingTypes: this.findingTypes,
      remediationTypes: this.remediationTypes,
      findingDetails: this.findingDetails,

      projectsLoaded: false,
      findingTypesLoaded: false,

      isLoaded: false,
      isSpinnerVisible: true,
      isInitialized: false
    }
  },

  created: function() {
    const defaultFilter = this.getFilterState();

    if (defaultFilter) {
      this.defaultFilter = JSON.parse(defaultFilter);
    }

    let tmpDetails = this.defaultFilter.details ? JSON.parse(this.defaultFilter.details) : null;

    let tmpFindingDetails = this.defaultFilter.findingDetails;

    this.defaultFilter.findingDetails = Object.assign({}, tmpDetails || tmpFindingDetails) || {};

    this.filter.siteId.push(this.siteId);
    this.filter.historical = this.defaultFilter.historical || false;
    this.filter.hasRemediation = this.defaultFilter.hasRemediation || false;
    this.filter.projectId = this.defaultFilter.projectId || [];
    this.filter.clientRemediatedRating = this.defaultFilter.clientRemediatedRating || [];
    this.filter.remediationPreviewRating = this.defaultFilter.remediationPreviewRating || []
    this.filter.physicalAssetTypeId = this.defaultFilter.physicalAssetTypeId || [];
    this.filter.componentTypeId = this.defaultFilter.componentTypeId || [];
    this.filter.findingTypeId = this.defaultFilter.findingTypeId || [];
    this.filter.remediationTypeId = this.defaultFilter.remediationTypeId || [];
    this.filter.findingDetails = this.defaultFilter.findingDetails || {};
    this.filter.status = this.defaultFilter.status || ['approved'];

    if (!this.filter.hasRemediation) {
      delete this.filter['hasRemediation'];
    }
  },

  mounted: function() {
    this.setFilterOptions();
  },

  watch: {
    isLoaded: function() {
      if (this.isLoaded) {
        // Select defualt filter projects
        if (this.defaultFilter.hasOwnProperty("projectId") && Object.keys(this.defaultFilter.projectId).length > 0) {
          let selectedProjects = [];

          this.projects.forEach((project) => {
            if (this.defaultFilter.projectId.includes(project.id)) {
              selectedProjects.push(project);
            };
          });

          this.$refs.projects.updateValue(selectedProjects);
        }

        // Select defualt filter physicalAssetTypes
        if (this.defaultFilter.hasOwnProperty("physicalAssetTypeId") && Object.keys(this.defaultFilter.physicalAssetTypeId).length > 0) {
          let selectedPhysicalAssetTypes = [];

          this.physicalAssetTypes.forEach((physicalAssetType) => {
            if (this.defaultFilter.physicalAssetTypeId.includes(physicalAssetType.id)) {
              selectedPhysicalAssetTypes.push(physicalAssetType);
            };
          });

          this.$refs.physicalAssetTypes.updateValue(selectedPhysicalAssetTypes);
        }

        // Select defualt filter componentTypes
        if (this.defaultFilter.hasOwnProperty("componentTypeId") && Object.keys(this.defaultFilter.componentTypeId).length > 0) {
          let selectedComponentTypes = [];

          this.componentTypes.forEach((componentType) => {
            if (this.defaultFilter.componentTypeId.includes(componentType.id)) {
              selectedComponentTypes.push(componentType);
            };
          });

          this.$refs.componentTypes.updateValue(selectedComponentTypes);
        }

        // Select defualt filter findingTypes
        if (this.defaultFilter.hasOwnProperty("findingTypeId") && Object.keys(this.defaultFilter.findingTypeId).length > 0) {
          let selectedFindingTypes = [];

          this.findingTypes.forEach((findingType) => {
            if (this.defaultFilter.findingTypeId.includes(findingType.id)) {
              selectedFindingTypes.push(findingType);
            };
          });

          this.$refs.findingTypes.updateValue(selectedFindingTypes);
        }

        // Select defualt filter remediationTypes
        if (this.defaultFilter.hasOwnProperty("remediationTypeId") && Object.keys(this.defaultFilter.remediationTypeId).length > 0) {
          let selectedRemediationTypes = [];

          this.remediationTypes.forEach((remediationType) => {
            if (this.defaultFilter.remediationTypeId.includes(remediationType.id)) {
              selectedRemediationTypes.push(remediationType);
            };
          });

          this.$refs.remediationTypes.updateValue(selectedRemediationTypes);
        }

        // Hide spinner
        setTimeout(() => {
          this.isSpinnerVisible = false;

          this.isInitialized = true;
        }, 1000);
      }
    }
  },

  computed: {
    isInPrevievMode: function() {
      return ['approved', 'draft', 'review'].every((val) => this.filter.status.includes(val));
    },

    isDetailsVisible: function() {
      let isFindingDetails = false;
      let isFindingTypes = false;

      if (this.findingDetails != undefined) {
        isFindingDetails = this.findingDetails.length > 0;
      }

      isFindingTypes = this.filter.findingTypeId.length > 0;

      return this.showFindingDetailsFilter && isFindingTypes && isFindingDetails;
    },

    isFilterEmpty: function() {
      return this.filter.historical == false && this.filter.hasRemediation == false && this.filter.projectId.length == 0 && this.filter.clientRemediatedRating.length == 0 && this.filter.remediationPreviewRating.length == 0 && this.filter.physicalAssetTypeId.length == 0 && this.filter.componentTypeId.length == 0 && this.filter.findingTypeId.length == 0 && this.filter.remediationTypeId.length == 0 && Object.keys(this.filter.findingDetails).length === 0 && !this.isInPrevievMode;
    },
  },

  methods: {
    clearFilter: function() {
      this.isInitialized = false;

      this.filter = {
        historical: false,
        projectId: [],
        clientRemediatedRating: [],
        remediationPreviewRating: [],
        siteId: [],
        physicalAssetTypeId: [],
        componentTypeId: [],
        findingTypeId: [],
        remediationTypeId: [],
        findingDetails: {},
        status: []
      }

      this.filter.siteId.push(this.siteId);

      this.$refs.ratingClassification.reset();
      this.$refs.projects.clearSelection();
      this.$refs.physicalAssetTypes.clearSelection();
      this.$refs.componentTypes.clearSelection();
      this.$refs.findingTypes.clearSelection();
      this.$refs.remediationTypes.clearSelection();

      this.isInitialized = true;

      this.setFilter();

      setTimeout(() => {
        app.ui.state.update({
          "v-filter": null
        });
      }, 500);
    },

    setPreviewMode: function($event) {
      this.filter.status = $event.target.checked ? ['approved', 'review', 'draft'] : ['approved'];

      // clear ratings filter
      this.filter.clientRemediatedRating = [];
      this.filter.remediationPreviewRating = [];
      this.$refs.ratingClassification.reset();

      this.setFilter();
    },

    setHistorical: function($event) {
      this.filter.historical = !this.filter.historical;

      this.filter.projectId = [];

      this.$refs.projects.clearSelection();

      this.setFilter();
    },

    setRemediatedFindings: function($event) {
      this.filter.hasRemediation = !this.filter.hasRemediation;

      if (!this.filter.hasRemediation) {
        delete this.filter['hasRemediation'];
      }

      this.setFilter();
    },

    setRating: function(selectedRating) {
      this.filter.clientRemediatedRating = [];
      this.filter.remediationPreviewRating = [];

      if (this.isInPrevievMode) {
        this.filter.remediationPreviewRating = selectedRating;
      }
      else {
        this.filter.clientRemediatedRating = selectedRating;
      }

      this.setFilter();
    },

    selectProject: function(opt) {
      this.filter.projectId = opt.map((el) => {
        return el.id;
      });

      this.setFilter();
    },

    deselectProject: function(opt) {
      this.filter.projectId = this.filter.projectId.filter(id => id !== opt.id)

      this.setFilter();
    },

    selectPhysicalAssetType: function(opt) {
      this.filter.physicalAssetTypeId = opt.map((el) => {
        return el.id;
      });

      this.setFilter();
    },

    deselectPhysicalAssetType: function(opt) {
      this.filter.physicalAssetTypeId = this.filter.physicalAssetTypeId.filter(item => item !== opt.id)

      this.setFilter();
    },

    selectComponentType: function(opt) {
      this.filter.componentTypeId = opt.map((el) => {
        return el.id;
      });

      this.setFilter();
    },

    deselectComponentType: function(opt) {
      this.filter.componentTypeId = this.filter.componentTypeId.filter(id => id !== opt.id)

      this.setFilter();
    },

    selectFindingType: function(opt) {
      this.filter.findingTypeId = opt.map((el) => {
        return el.id;
      });

      this.clearFindingDetails();

      this.setFilter();
    },

    deselectFindingType: function(opt) {
      this.filter.findingTypeId = this.filter.findingTypeId.filter(id => id !== opt.id);

      this.clearFindingDetails();

      this.setFilter();
    },

    selectRemediationType: function(opt) {
      this.filter.remediationTypeId = opt.map((el) => {
        return el.id;
      });

      this.setFilter();
    },

    deselectRemediationType: function(opt) {
      this.filter.remediationTypeId = this.filter.remediationTypeId.filter(id => id !== opt.id)

      this.setFilter();
    },

    selectFindingTypeDetails: function(opt, el) {
      this.filter.findingDetails[el.name] = opt.map((val) => {
        return val
      });

      this.setFilter();
    },

    deselectFindingTypeDetails: function(opt, el) {
      this.filter.findingDetails[el.name] = opt.map((val) => {
        return val
      });

      this.setFilter();
    },

    findingsDetailsDefaultValues: function(detail) {
      let defaultValues = [];

      if (this.defaultFilter.findingDetails) {
        if (Object.keys(this.defaultFilter.findingDetails).includes(detail.name)) {
          defaultValues = this.defaultFilter.findingDetails[detail.name];
        }
      }

      return defaultValues;
    },

    clearFindingDetails: function() {
      this.defaultFilter.findingDetails = {};
      this.filter.findingDetails = {};
    },

    setFilterOptions: function() {
      let optionsFilter = Object.assign({}, this.filter);

      delete optionsFilter['findingDetails'];

      app.graphql.get("filter.select.findingOptions",
        {
          filter: optionsFilter
        },

        (data) => {          
          let filterOptions = data.data.findingFilterOptions;

          this.ratings = this.isInPrevievMode ? filterOptions.remediationPreviewRatings : filterOptions.clientRemediatedRatings;
          this.projects = filterOptions.projects;
          this.physicalAssetTypes = filterOptions.physicalAssetTypes;
          this.componentTypes = filterOptions.componentTypes;
          this.findingTypes = filterOptions.findingTypes;
          this.remediationTypes = filterOptions.remediationTypes;
          this.findingDetails = filterOptions.findingDetails;

          this.isLoaded = true;
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch filter options!"
          });
        }
      )
    }
  }
}

</script>
