<template>
  <tr :class="{'is-marked': marked}">
    <td>
      <a
        :href="inspectionUrl"
        :title="name"
      >{{name}}</a>
      <div v-if="notes" class="note">{{notes}}</div>
    </td>

    <td>
      <a
        :href="siteUrl"
        :title="siteTitle"
      >{{site.name}}</a>
    </td>

    <td class="text-center">
      <template v-if="policyUpdate && findingStatusCounts.draft > 0">
        <CellAction
          action="review"
          text="Review"
          title="Review all (In Draft) findings"
          confirm="Are you sure? Status of all Activity findings will be changed to In Review!"
          :label="findingsInDraft"
          @clickAction="reviewFindings"
        />
      </template>
      <template v-else>
        {{draftFindingsCount}}
      </template>
    </td>

    <td class="text-center">
      <template v-if="policyUpdate && findingStatusCounts.review > 0">
        <CellAction
          action="approve"
          text="Approve"
          title="Approve all (In Review) findings"
          confirm="Are you sure? All Activity findings will be approved!"
          :label="findingsInReview"
          @clickAction="approveFindings"
        />
      </template>
      <template v-else>
        {{reviewFindingsCount}}
      </template>
    </td>

    <td class="text-center">{{approvedFindingsCount}}</td>

    <td class="text-center">
      <template v-if="policyUpdate && remediationStatusCounts.draft > 0">
        <CellAction
          action="review"
          text="Review"
          title="Review all (In Draft) remeditations"
          confirm="Are you sure? Status of all Activity remediations will be changed to In Review!"
          :label="remediationsInDraft"
          @clickAction="reviewRemediations"
        />
      </template>
      <template v-else>
        {{draftRemediationsCount}}
      </template>
    </td>

    <td class="text-center">
      <template v-if="policyUpdate && remediationStatusCounts.review > 0">
        <CellAction
          action="approve"
          text="Approve"
          title="Approve all (In Review) remediations"
          confirm="Are you sure? All Activity remediations will be approved!"
          :label="remediationsInReview"
          @clickAction="approveRemediations"
        />
      </template>
      <template v-else>
        {{reviewRemediationsCount}}
      </template>
    </td>

    <td class="text-center">{{approvedRemediationsCount}}</td>

    <td class="text-end">
      {{ h$.formatDate.short(date) }}
    </td>

    <td class="table-row-actions">
      <RowAction
        :href="inspectionUrl"
        action="read"
      />

      <RowAction
        v-if="policyUpdate"
        :href="inspectionUrl + '/edit'"
        action="update"
      />

      <RowAction
        v-if="policyUpdate"
        :href="inspectionUrl"
        action="delete"
      />

      <RowAction
        v-if="policyUpdate"
        :href="findingsImportUrl"
        title="Import Findings"
        action="import"
      />
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import CellAction from "../shared/grid/cell_action";
import RowAction from "../shared/grid/row_action";

import helpers from "../../helpers.js";

export default {
  extends: Row,

  components: {
    CellAction,
    RowAction
  },

  props: {
    id: String,
    findingStatusCounts: Object,
    remediationStatusCounts: Object,
    name: String,
    notes: String,
    date: String,
    site: Object,
    projectId: String
  },

  setup () {
    return {
      h$: helpers
    }
  },

  computed: {
    inspectionUrl: function() {
      return "/inspections/" + this.id;
    },

    siteUrl: function() {
      return "/sites/" + this.site.id + '?v-filter={"projectId":["' + this.projectId + '"], "historical":true}';
    },

    siteTitle: function() {
      return "Site: " + this.site.name;
    },

    findingsImportUrl: function() {
      return "/finding_imports/new?project_id=" + this.projectId;
    },

    findingsInReview: function() {
      return this.findingStatusCounts.review.toString();
    },

    findingsInDraft: function() {
      return this.findingStatusCounts.draft.toString();
    },

    reviewFindingsCount: function() {
      return (this.findingStatusCounts.review > 0) ? this.findingStatusCounts.review : "-";
    },

    approvedFindingsCount: function() {
      return (this.findingStatusCounts.approved > 0) ? this.findingStatusCounts.approved : "-";
    },

    draftFindingsCount: function() {
      return (this.findingStatusCounts.draft > 0) ? this.findingStatusCounts.draft : "-";
    },

    remediationsInReview: function() {
      return this.remediationStatusCounts.review.toString();
    },

    remediationsInDraft: function() {
      return this.remediationStatusCounts.draft.toString();
    },

    reviewRemediationsCount: function() {
      return (this.remediationStatusCounts.review > 0) ? this.remediationStatusCounts.review : "-";
    },

    approvedRemediationsCount: function() {
      return (this.remediationStatusCounts.approved > 0) ? this.remediationStatusCounts.approved : "-";
    },

    draftRemediationsCount: function() {
      return (this.remediationStatusCounts.draft > 0) ? this.remediationStatusCounts.draft : "-";
    }
  },

  methods: {
    approveFindings: function () {
      app.graphql.get(
        "update.inspection.approveFindings",

        {
          input: {
            inspectionId: this.id
          }
        },

        (data) => {
          const response = data.data.approveInspectionFindings;

          if (response.errors) {
            response.errors.forEach((error) => {
              app.ui.toast.add({
                priority: "warning",
                title: "Unable to update finding",
                message: error
              });
            })
          } else {
            app.ui.toast.add({
              priority: "success",
              title: "Inspection Findings",
              message: "All inspection findings from <strong>review</strong> status set to <strong>approved</strong>"
            })

            this.$emit("update-status", response.inspection);
          }
        }
      )
    },

    reviewFindings: function () {
      app.graphql.get(
        "update.inspection.reviewFindings",

        {
          input: {
            inspectionId: this.id
          }
        },

        (data) => {
          const response = data.data.reviewInspectionFindings;

          if (response.errors) {
            response.errors.forEach((error) => {
              app.ui.toast.add({
                priority: "warning",
                title: "Unable to update finding",
                message: error
              });
            })
          } else {
            app.ui.toast.add({
              priority: "success",
              title: "Inspection Findings",
              message: "All inspection findings from <strong>In Draft</strong> status set to <strong>In Review</strong>"
            })

            this.$emit("update-status", response.inspection);
          }
        }
      )
    },

    approveRemediations: function () {
      app.graphql.get(
        "update.inspection.approveRemediations",

        {
          input: {
            inspectionId: this.id
          }
        },

        (data) => {
          const response = data.data.approveInspectionRemediations;

          if (response.errors) {
            response.errors.forEach((error) => {
              app.ui.toast.add({
                priority: "warning",
                title: "Unable to update activity",
                message: error
              });
            })
          } else {
            app.ui.toast.add({
              priority: "success",
              title: "Inspection Remediations",
              message: "All inspection remediations from <strong>review</strong> status set to <strong>approved</strong>"
            })

            this.$emit("update-status", response.inspection);
          }
        }
      )
    },

    reviewRemediations: function () {
      app.graphql.get(
        "update.inspection.reviewRemediations",

        {
          input: {
            inspectionId: this.id
          }
        },

        (data) => {
          const response = data.data.reviewInspectionRemediations;

          if (response.errors) {
            response.errors.forEach((error) => {
              app.ui.toast.add({
                priority: "warning",
                title: "Unable to update activity",
                message: error
              });
            })
          } else {
            app.ui.toast.add({
              priority: "success",
              title: "Inspection Remediations",
              message: "All inspection remediations from <strong>In Draft</strong> status set to <strong>In Review</strong>"
            })

            this.$emit("update-status", response.inspection);
          }
        }
      )
    }
  }
}
</script>
