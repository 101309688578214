<template>
  <tr :class="{'is-marked': marked}">
    <td>
      <DetailsButton
        entity-type="physicalAssetDetails"
        title="Physical Asset Details"
        :entity="id"
      >
        <a
          :href="physicalAssetUrl + filterQueryParams"
        >{{name}}</a>
        <div v-if="description" class="note">{{description}}</div>
      </DetailsButton>
    </td>

    <td class="text-center">
      <DamageRating
        v-if="(!showInPreviewMode && latestSummary.approvedDate) || (showInPreviewMode && latestSummary.previewDate)" 
        :rating="showInPreviewMode ? latestSummary.remediationPreviewRating : latestSummary.clientRemediatedRating" 
      />
    </td>

    <td
      v-if="showPoleNumber"
      class="text-center"
    >
    {{detailPoleNumber}}
    </td>

    <td 
      v-if="showLeCondition"
      class="text-center"
    >
      <DamageRating
        v-if="(!showInPreviewMode && latestLeadingEdgeSummary.approvedDate) || (showInPreviewMode && latestLeadingEdgeSummary.previewDate)"
        :rating="showInPreviewMode ? latestLeadingEdgeSummary.remediationPreviewRating : latestLeadingEdgeSummary.clientRemediatedRating" 
      />
    </td>

    <td class="text-center asset-qr-label">
      <template v-if="policyUpdate && !qrLabel">
        <CellAction
          action="edit"
          text="Assign"
          title="Assign QR Label"
          icon="qrcode"
          @clickAction="assignQr"
        />
      </template>
      <template v-else>
        <span v-bind:class="['qr-label', {'is-more': isMoreQrLabelsAssigned }]">
          {{qrLabelNumber}}
        </span>
      </template>
    </td>

    <td class="text-end">
      {{ lastActivityDate }}
    </td>

    <td class="text-end">
      {{ lastReplacementDate }}
    </td>

    <td class="table-row-actions">
      <RowAction
        :href="physicalAssetUrl + filterQueryParams"
        action="read"
      />

      <RowAction
        v-if="policyUpdate"
        :href="physicalAssetUrl + '/edit'"
        action="update"
      />

      <RowAction
        v-if="policyUpdate"
        :href="physicalAssetUrl"
        action="delete"
      />
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import CellAction from "../shared/grid/cell_action";
import RowAction from "../shared/grid/row_action";
import DamageRating from "../shared/damage_rating";
import DetailsButton from "../shared/grid/details_button";

import Helpers from "../../helpers.js";

export default {
  extends: Row,

  components: {
    RowAction,
    CellAction,
    DamageRating,
    DetailsButton
  },

  props: {
    filter: Object,
    id: String,
    name: String,
    description: String,
    details: String,
    latestSummary: Object,
    latestLeadingEdgeSummary: Object,
    latestReplacement: Object,
    projectSummary: Object,
    site: Object,
    qrLabel: Object,
    qrLabels: Object,
    isProjectFilter: Boolean,
    showLeCondition: Boolean,
    showInPreviewMode: Boolean,
    showPoleNumber: Boolean
  },

  setup () {
    return {
      h$: Helpers
    }
  },

  methods: {
    assignQr: function () {
      const vm = this;

      vmModal.$refs.modal.show({
        template: 'physicalAssetQR',
        title: 'Asset QR Label',
        args: {
          asset: vm,
          callback: () => {
            // Refresh assets grid
            if (window.vmPhysicalAssets && vmPhysicalAssets.$refs.physicalAssets) {
              vmPhysicalAssets.$refs.physicalAssets.refresh()
            }
            if (window.vmSitePhysicalAssets && vmSitePhysicalAssets.$refs.sitePhysicalAssets) {
              vmSitePhysicalAssets.$refs.sitePhysicalAssets.refresh()
            }

            // Refresh asset details sidebar
            const assetDetailsPane = vmDetailsPane.$refs.detailsPane.$refs.entityDetails;

            if ( assetDetailsPane && 'fetchAssetDetails' in assetDetailsPane) {
              assetDetailsPane.fetchAssetDetails()
            }
          }
        }
      })
    }
  },

  computed: {
    isFilterEmpty: function() {
      // Ignore filter if there is only siteId in filter
      return (Object.keys(this.filter).filter(x => !["siteId"].includes(x))).length === 0;
    },

    filterQueryParams: function() {
      let filterParams = Object.assign({}, this.filter);
      
      delete filterParams.siteId;
      delete filterParams.physicalAssetTypeId;

      filterParams.physicalAssetId = this.id;

      return (!this.isFilterEmpty) ? "?v-filter=" + JSON.stringify(filterParams) : "";
    },

    physicalAssetUrl: function () {
      return "/physical_assets/" + this.id;
    },

    lastActivityDate: function() {
      const activityDate = this.showInPreviewMode ? this.latestSummary.previewDate : this.latestSummary.approvedDate;

      return activityDate ? Helpers.formatDate.short(activityDate) : "N/A";
    },

    lastReplacementDate: function() {
      return this.latestReplacement ? Helpers.formatDate.short(this.latestReplacement.inspection.date) : '';
    },

    isMoreQrLabelsAssigned: function() {
      return this.qrLabels.edges.length > 1;
    },

    qrLabelNumber: function() {
      return (this.qrLabel) ? this.qrLabel.labelNumber  : "-";
    },

    detailPoleNumber: function() {
      const details = (this.details) ? JSON.parse(this.details) : {};
      
      return details['Pole number'] || '';
    }
  }
}
</script>
