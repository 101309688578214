<template>
  <tr>
    <td>
      <a 
        title="Download Document" 
        target="_blank" 
        :download="fileName" 
        rel="noreferrer nofollow" 
        :href="url" 
        :class="documentIcon"
      >{{caption}}</a>
    </td>

    <td
      v-if="policyUpdate"
      class="text-center"
    >
      <template v-if="isInternal">
        <span class="label-default">internal</span>
      </template>
    </td>
    
    <td
      v-if="showAssociation"
      class="text-start"
    >
      <a :href="documentable.url">{{ documentable.displayName }}</a>
      <div class="note">{{ documentable.__typename }}</div>
    </td>

    <td class="text-start">
      {{ documentExtension }}
    </td>

    <td class="text-end">
      {{ h$.formatDate.short(updatedAt) }}
    </td>

    <td class="table-row-actions">
      <RowAction
        action="download"
        title="Download Document" 
        target="_blank" 
        :download="fileName" 
        rel="noreferrer nofollow" 
        :href="url"
      />

      <RowAction
        v-if="policyUpdate"
        :action="toggleDocumentAction"
        :title="toggleDocumentTitle"
        @click.prevent="toggleDocument"
      />

      <RowAction
        v-if="policyUpdate"
        action="remove"
        @click.prevent="removeDocument"
      />
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import CellAction from "../shared/grid/cell_action";
import RowAction from "../shared/grid/row_action";

import helpers from "../../helpers.js"

export default {
  extends: Row,
  
  components: {
    CellAction,
    RowAction
  },

  props: {
    id: String,
    fileName: String,
    caption: String,
    createdAt: String,
    updatedAt: String,
    url: String,
    internal: {
      type: Boolean,
      default: false
    },
    documentable: Object,
    showAssociation: Boolean
  },

  data: function () {
    return {
      isInternal: false
    }
  },

  setup () {
    return {
      h$: helpers
    }
  },

  mounted: function() {
    this.isInternal = this.internal
  },

  computed: {
    documentUrl: function() {
      return "/documents/" + this.id;
    },

    documentIcon: function() {
      let icon = ["jpg", "jpeg", "gif", "bmp", "png"].includes(this.documentExtension) ? "image" : this.documentExtension;

      return "document-type-" + icon;
    },

    documentExtension: function() {
      return this.fileName.substring(this.fileName.lastIndexOf('.')+1, this.fileName.length)
    },

    toggleDocumentTitle: function() {
      return this.isInternal ? "Switch to public" : "Switch to internal"
    },

    toggleDocumentAction: function() {
      return this.isInternal ? "hide" : "show"
    }
  },

  methods: {
    removeDocument: function() {
      this.$emit("remove-document", this.url);
    },

    toggleDocument: function() {
      let vm = this;
      
      app.graphql.get(
        "update.document.toggleInternal",

        {
          input: {
            documentId: vm.id,
            internal: !vm.isInternal
          }
        },

        (data) => {
          const response = data.data.toggleInternalDocument;

          if (response.errors) {
            response.errors.forEach((error) => {
              app.ui.toast.add({
                priority: "warning",
                title: "Unable to update document",
                message: error
              });
            })
          } else {
            vm.isInternal = response.document.internal;

            app.ui.toast.add({
              priority: "success",
              title: "Document visibility",
              message: "Document <strong>" + vm.caption + "</strong> visibility is set to <strong>" + (vm.isInternal ? "internal" : "public") + "</strong>"
            });
          }
        }
      )
    }
  }
}
</script>