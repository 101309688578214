<template>
  <div class="row">
    <div class="col-sm-6">
      <div class="form-group">
        <label class="placeholder placeholder-inactive" for="inspected_assets_project_prefix">Project Prefix</label>
        <input
          :class="['form-control', {'error': v$.projectPrefix.$error}]"
          placeholder="Prefix"
          type="text"
          name="project_prefix"
          id="inspected_assets_project_prefix"
          v-model.trim="projectPrefix"
          @input="$emit('update:parameters', { ...parameters, 'project_prefix': projectPrefix })"
        />
        <div v-if="v$.projectPrefix.$error" class="error">Project prefix is required</div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  props: {
    parameters: {
      type: Object,
      required: true
    }
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      projectPrefix: ''
    }
  },

  validations () {
    return {
      projectPrefix: {
        required
      }
    }
  }
}
</script>
