<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="form-group">
        <label class="placeholder placeholder-inactive">Client Company</label>
        <v-select
          placeholder="Select client company"
          ref="clientCompanies"
          :class="[{'vs-error': v$.clientCompany.$error}]"
          :options="clientCompanies"
          label="name"
          v-model="clientCompany"
          @update:modelValue="$emit('update:parameters', { ...parameters, 'client_company_id': clientCompany ? clientCompany.id : '' })"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <div class="form-group">
        <label class="placeholder placeholder-inactive" for="current_component_status_project_prefix">Project Prefix</label>
        <input
          :class="['form-control', {'error': v$.projectPrefix.$error}]"
          placeholder="Prefix"
          type="text"
          name="project_prefix"
          id="current_component_status_project_prefix"
          v-model.trim="projectPrefix"
          @input="$emit('update:parameters', { ...parameters, 'project_prefix': projectPrefix })"
        />
        <div v-if="v$.clientCompany.$error && v$.projectPrefix.$error" class="error">Either Client info or Project prefix is required</div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators'

import vSelect from 'vue-select';

const conditionalyRequired = (value, vm) => helpers.req(vm.projectPrefix) || helpers.req(vm.clientCompany)

export default {
  components: {
    vSelect
  },

  props: {
    parameters: {
      type: Object,
      required: true
    }
  },

  watch: {
    parameters: function() {      

    }
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      clientCompanies: this.clientCompanies,
      clientCompany: null,
      projectPrefix: '',
    }
  },

  validations () {
    return {
      projectPrefix: {
        conditionalyRequired
      },
      clientCompany: {
        conditionalyRequired
      }
    }
  },

  mounted: function() {
    this.fetchClientCompanies();
  },

  methods: {
    fetchClientCompanies: function() {
      let fetchParams = {
         orderBy: {
          field: "NAME",
          direction: "ASC"
        }
      };

      this.clientCompanies = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.clientCompanies",
          fetchParams,

          (data) => {
            let clientCompanies = data.data.clientCompanies,
                pageInfo = clientCompanies.pageInfo;

            success(clientCompanies, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Client Companies!"
            });
          }
        )
      };

      let getClientCompanies = (cursor) => {
        fetch(
          cursor,

          (clientCompanies, pageInfo) => {
            clientCompanies.edges.forEach((edge) => this.clientCompanies.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getClientCompanies(pageInfo.endCursor))
            }
          }
        )
      };

      getClientCompanies(window.btoa(0));
    },
  }
}
</script>
