<template>
  <div class="table-wrapper">
    <div
      class="table-responsive"
      :class="{ 'spinner-is-visible': isSpinnerVisible }"
    >
      <table class="table">
        <thead>
          <tr>
            <SortableHeader
              style="width: 300px"
              :current-sort="orderBy"
              :sort-by="'USER_EMAIL'"
              @sort="reorder"
            >
              User
            </SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'USER_NAME'"
              @sort="reorder"
            >
              Name
            </SortableHeader>

            <SortableHeader
              style="width: 140px"
              :current-sort="orderBy"
              :sort-by="'ROLE'"
              @sort="reorder"
            >
              Role
            </SortableHeader>

            <th 
              v-if="policyUpdate"
              data-sorter="false"
              class="text-center"
              style="width: 140px"
            >
              Status
            </th>

            <th 
              v-if="policyUpdate"
              data-sorter="false"
              class="text-end"
              style="width: 140px"
            >
              Last Active
            </th>

            <th data-sorter="false"></th>
          </tr>
        </thead>

        <tbody>
          <User
            v-for="(item, index) in items"
            v-bind="item"
            :key="item.id"
            :index="index"
            :policy-update="policyUpdate"
          />
        </tbody>
      </table>

      <div class="spinner"></div>

      <NoRecords
        label="No matching users found"
      />

      <Pagination
        v-bind="pageInfo"
        entity-type="users"
        @navigate-to="paginate"
      />
    </div>
  </div>
</template>

<script>
import Grid from "../shared/grid/grid";

import User from "./user";

export default {
  extends: Grid,

  components: {
    User
  },

  props: {
    clientCompanyId: String
  },

  data: function () {
    return {
      items: [],

      grid: "client-company-users",

      defaultOrderBy: {
        field: "USER_NAME",
        direction: "ASC"
      },

      orderBy: {
        field: "USER_NAME",
        direction: "ASC"
      },

      pageInfo: {
        pageSize: 20,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true
    };
  },

  computed: {
    filterParams: function () {
      let params = {
        clientCompanyId: this.clientCompanyId
      };

      return params;
    },

    queryName: function () {
      if (this.policyUpdate) {
        return "grid.clientCompany.usersAll";
      } else {
        return "grid.clientCompany.users";
      }
    }
  },

  methods: {
    fetch: function (params, success) {
      app.graphql.get(this.queryName,
        params,

        (data) => {
          let client_roles = data.data.clientRoles,
              pageInfo = client_roles.pageInfo;

          success(client_roles, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch users!"
          })
        }
      )
    }
  }
}
</script>
