<template>
  <div class="table-wrapper">
    <div class="row d-flex mb-2">
      <div class="col-sm-6 col-xs-7">
        <SearchField
          v-model:textSearch="textSearch"
          placeholder="Search for users by name or email"
          @input="isSpinnerVisible = true"
          @search="search"
        />
      </div>
      <div class="col-sm-6 col-xs-5 d-flex align-items-center justify-content-end">
        <a
          href="#"
          :title="[isGroupedByState ? 'Ungroup' : 'Group']"
          class="btn-expandable btn-expandable-default btn-expandable-sm"
          @click.prevent="toggleGroup"
        >
          <span :data-icon="[isGroupedByState ? 'check_box_outline' : 'check_box_outline_blank']" class="icon"></span> <span>Group by Status</span>
        </a>
      </div>
    </div>

    <div
      class="table-responsive"
      :class="{ 'spinner-is-visible': isSpinnerVisible }"
    >
      <table :class="['table', {'group-rows': isGroupedByState}]">
        <thead>
          <tr>
            <SortableHeader
              style="width: 300px"
              :current-sort="orderBy"
              :sort-by="'EMAIL'"
              @sort="reorder"
            >
              User
            </SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'NAME'"
              @sort="reorder"
            >
              Name
            </SortableHeader>

            <SortableHeader
              style="width: 140px"
              :current-sort="orderBy"
              :sort-by="'ROLE'"
              @sort="reorder"
            >
              Role
            </SortableHeader>

            <SortableHeader
              class="text-center"
              style="width: 140px"
              :current-sort="orderBy"
              :sort-by="'ACTIVATION_STATE'"
              @sort="reorder"
            >
              Status
            </SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'LAST_ACTIVITY_AT'"
              @sort="reorder"
              class="text-end"
              style="width: 140px"
            >
              Last Active
            </SortableHeader>

            <th data-sorter="false"></th>
          </tr>
        </thead>

        <tbody>
          <User
            v-for="(item, index) in items"
            v-bind="item"
            :key="item.id"
            :index="index"
          />
        </tbody>
      </table>

      <div class="spinner"></div>

      <NoRecords
        label="No matching users found"
      />

      <Pagination
        v-bind="pageInfo"
        entity-type="users"
        @navigate-to="paginate"
      />
    </div>
  </div>
</template>

<script>
import Grid from "../shared/grid/grid";

import User from "./list_row";

export default {
  extends: Grid,

  components: {
    User
  },

  data: function () {
    return {
      items: [],

      grid: "users",

      textSearch: '',

      defaultOrderBy: {
        field: "NAME",
        direction: "ASC"
      },

      orderBy: {
        field: "NAME",
        direction: "ASC"
      },

      pageInfo: {
        pageSize: 50,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true,
      isGroupedByState: true
    };
  },

  computed: {
    filterParams: function () {
      if (this.textSearch) {
        return {
          text: {
            value: this.textSearch,
            fields: this.textSearchFields
          }
        };
      }
    },

    textSearchFields: function () {
      return ["EMAIL", "NAME"];
    },

    queryOrder: function () {
      let orderParams = [];

      if (this.orderBy.field != "ACTIVATION_STATE" && this.isGroupedByState) {
        orderParams.push({
          field: "ACTIVATION_STATE",
          direction: "ASC"
        });
      }

      orderParams.push(this.orderBy);

      return orderParams;
    }
  },

  methods: {
    toggleGroup: function () {
      this.isGroupedByState = !this.isGroupedByState;

      this.refresh();
    },

    fetch: function (params, success) {
      app.graphql.get("grid.users",
        params,

        (data) => {
          const users = data.data.users;

          success(users);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch users!"
          });
        }
      );
    }
  }
}
</script>
