<template>
  <tr :class="{'is-marked': marked}">
    <td>
      <DetailsButton
        title="Physical Asset Details"
        entity-type="physicalAssetDetails"
        :entity="id"
      >
        <a
          :href="physicalAssetUrl"
        >{{name}}</a>
        <div v-if="description" class="note">{{description}}</div>
      </DetailsButton>
    </td>

    <td>
      <DetailsButton
        title="Site Details"
        entity-type="siteDetails"
        :entity="site.id"
      >
        <a
          :href="siteUrl"
        >{{site.name}}</a>
        <div v-if="site.description" class="note">{{site.description}}</div>
      </DetailsButton>
    </td>

    <td>
      <Details :details="details" />
    </td>

    <td class="text-center">
      <DamageRating
        v-if="latestSummary.approvedDate" 
        :rating="latestSummary.clientRemediatedRating" 
      />
    </td>

    <td class="text-end">
      {{lastActivityDate}}
    </td>

    <td class="table-row-actions">
      <RowAction
        :href="physicalAssetUrl"
        action="read"
      />

      <RowAction
        v-if="policyUpdate"
        :href="physicalAssetUrl + '/edit'"
        action="update"
      />

      <RowAction
        v-if="policyUpdate"
        :href="physicalAssetUrl"
        action="delete"
      />
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import RowAction from "../shared/grid/row_action";
import Details from "../shared/grid/details";
import DamageRating from "../shared/damage_rating";
import DetailsButton from "../shared/grid/details_button";

import helpers from "../../helpers.js";

export default {
  extends: Row,

  components: {
    RowAction,
    Details,
    DamageRating,
    DetailsButton
  },

  props: {
    id: String,
    name: String,
    description: String,
    details: String,
    latestSummary: Object,
    site: Object
  },

  computed: {
    physicalAssetUrl: function () {
      return "/physical_assets/" + this.id;
    },

    siteUrl: function () {
      return "/sites/" + this.site.id;
    },

    lastActivityDate: function() {
      const activityDate = this.latestSummary.approvedDate;

      return activityDate ? helpers.formatDate.short(activityDate) : "N/A";
    },

  }
}
</script>
