<template>
<div class="table-wrapper">
  <div class="row">
    <div class="col-md-6 col-sm-12 grid-search-control">
      <SearchField
        v-model:textSearch="textSearch"
        placeholder="Search for activities by name, project, or site"
        @input="isSpinnerVisible = true"
        @search="search"
      />
    </div>
  </div>

  <div
    class="table-responsive"
    v-bind:class="{ 'spinner-is-visible': isSpinnerVisible }"
  >
    <table class="table">
      <thead>
        <tr>
          <SortableHeader
            class="text-start"
            style="width: 300px"
            :current-sort="orderBy"
            :sort-by="'NAME'"
            @sort="reorder"
          >Activity</SortableHeader>

          <SortableHeader
            style="width: 300px"
            :current-sort="orderBy"
            :sort-by="'PROJECT_NAME'"
            @sort="reorder"
          >Project</SortableHeader>

          <SortableHeader
            :current-sort="orderBy"
            :sort-by="'SITE_NAME'"
            @sort="reorder"
          >Site</SortableHeader>

          <SortableHeader
            class="text-center"
            style="width: 110px"
            :current-sort="orderBy"
            :sort-by="'DRAFT_FINDINGS'"
            @sort="reorder"
          >In Draft</SortableHeader>

          <SortableHeader
            class="text-center"
            style="width: 110px"
            :current-sort="orderBy"
            :sort-by="'REVIEW_FINDINGS'"
            @sort="reorder"
          >In Review</SortableHeader>

          <SortableHeader
            class="text-center"
            style="width: 110px"
            :current-sort="orderBy"
            :sort-by="'APPROVED_FINDINGS'"
            @sort="reorder"
          >Approved</SortableHeader>

          <SortableHeader
            class="text-end"
            style="width: 120px"
            :current-sort="orderBy"
            :sort-by="'DATE'"
            @sort="reorder"
          >Date</SortableHeader>

          <th data-sorter="false"></th>
        </tr>
      </thead>

      <tbody>
        <Inspection
          v-for="(item, index) in items"
          v-bind="item"
          :key="item.id"
          :index="index"
          :show-client="showClient"
          :policy-update="policyUpdate"
          @update-status="approveInspectionFindings"
          :ref="item.id"
          :selected="isItemMarked(item.id)"
        />
      </tbody>
    </table>

    <div class="spinner"></div>

    <NoRecords 
      label="According to the search criteria, there are no activities found."
    />

    <Pagination
      v-bind="pageInfo"
      entity-type="activities"
      @navigate-to="paginate"
    />
  </div>
</div>
</template>

<script>
import Grid from "../shared/grid/grid";

import Inspection from "./inspection";

export default {
  extends: Grid,

  components: {
    Inspection
  },

  props: {
    showClient: Boolean,
    policyUpdate: Boolean
  },

  data: function () {
    return {
      items: [],

      grid: "inspections",

      textSearch: '',

      defaultOrderBy: {
        field: "NAME",
        direction: "ASC"
      },
      
      orderBy: {
        field: "NAME",
        direction: "ASC"
      },

      pageInfo: {
        pageSize: 20,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true
    }
  },

  computed: {
    filterParams: function () {
      if (this.textSearch) {
        return {
          text: {
            value: this.textSearch,
            fields: this.textSearchFields
          }
        };
      }
    },

    textSearchFields: function () {
      return ["NAME", "PROJECT_NAME", "SITE_NAME"];
    }
  },

  methods: {
    fetch: function (params, success) {
      app.graphql.get("grid.inspections",
        params,

        (data) => {
          let inspections = data.data.inspections,
              pageInfo = inspections.pageInfo;

          success(inspections, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch inspections!"
          })
        }
      )
    },

    approveInspectionFindings: function (updatedInspection) {
      let inspection = this.items.find(function (item) {
        return item.id === updatedInspection.id;
      });

      inspection.findingStatusCounts.approved = updatedInspection.findingStatusCounts.approved;
      inspection.findingStatusCounts.review = 0;
    }
  }
}
</script>
