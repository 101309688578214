<template>
<div 
  v-if="display == 'block'"
>
  <div 
    :class="['modal', style.class, 'fade', fade]"
    tabindex="-1" role="dialog" 
    aria-hidden="true"
    :style="{'display': display}"
    @click="!alwaysOpen ? hide() : {}"
  >
    <div 
      :class="['modal-dialog', {'modal-dialog-centered': style.verticalyCentered}, size]"
      :style="[{'width': style.width}, {'max-width': style.maxWidth}, {'height': style.height}, {'max-height': style.maxHeight}]"
      @click.stop
    >
      <div class="modal-content" :style="[{'width': style.width || '100%'}, {'height': style.height}]">
        <div 
          v-if="title"
          class="modal-header">
          <h5 class="modal-title fs-5" id="comunication-center-modal-label">{{ title }}</h5>
          <button
            v-if="!alwaysOpen"
            type="button"
            class="btn-close" 
            data-dismiss="modal"
            aria-label="Close"
            @click="hide"
          ></button>
        </div>
        <component 
          v-bind:is="template"
          :user-can-update="userCanUpdate"
          :args="args"
          @close="hide"
        ></component>
      </div>
    </div>
  </div>
  <div :class="['modal-backdrop', 'fade', fade]"></div>
</div>
</template>

<script>
import { nextTick } from 'vue'

import blankModal from './blank_modal'
import physicalAssetQR from './physical_asset_qr_modal'
import imageEditor from './image_editor_modal'
import siteBulkUpdateModal from './site_bulk_update_modal'
import siteDetailsModal from './site_details_modal'
import siteImportTemplateModal from './site_import_template_modal'
import findingsImportTemplateModal from './findings_import_template_modal'
import componentImportTemplateModal from './component_import_template_modal'
import qrBatchCreateModal from './qr_batch_create_modal'
import documentsUploadModal from './documents_upload_modal'
import inspectorNewModal from './inspector_new_modal'
import communicationCenterModal from './communication_center_modal'
import userBasicDataModal from './user_basic_data_modal'
import userPasswordModal from './user_password_modal'
import userDualAuthenticationModal from './user_dual_authentication_modal'
import forgotPasswordModal from '../../sessions/forgot_password_modal'
import tocModal from './toc_modal'
import photoGallery from './photo_gallery_modal'
import findingsExportModal from './findings_export_modal'


export default {
  components: {
    blankModal,
    physicalAssetQR,
    imageEditor,
    siteBulkUpdateModal,
    siteDetailsModal,
    siteImportTemplateModal,
    findingsImportTemplateModal,
    componentImportTemplateModal,
    qrBatchCreateModal,
    documentsUploadModal,
    inspectorNewModal,
    communicationCenterModal,
    userBasicDataModal,
    userPasswordModal,
    userDualAuthenticationModal,
    forgotPasswordModal,
    tocModal,
    photoGallery,
    findingsExportModal
  },

  props: {
    userCanUpdate: Boolean
  },

  setup () {},

  data() {
    return {
      title: '',
      template: '',
      display: 'none',
      fade: '',
      alwaysOpen: false,
      style: {
        size: '',
        verticalyCentered: false,
        width: '',
        height: '',
        maxWidth: '',
        maxHeight: '',
        class: ''
      },
      args: {}
    }
  },

  mounted: function() {
    var vm = this

    window.onresize = () => {
      if (!this.alwaysOpen && !this.style.fullscreen) {
        vm.hide()
      }
    }

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        if (!this.alwaysOpen) {
          vm.hide()

          document.querySelectorAll('.tooltip').forEach(el => el.remove())
        }
      }
    })
  },

  computed: {
    size: function() {
      return this.style.size && this.style.size != '' ? 'modal-' + this.style.size : ''
    }
  },

  methods: {
    show: function(opts) {
      document.querySelectorAll('.tooltip').forEach(el => el.remove())

      this.template = opts.template || 'blankModal'
      this.title = opts.title || ''
      this.style = opts.style || {}
      this.alwaysOpen = opts.alwaysOpen || false
      this.args = opts.args || {}
      
      this.display = 'block'

      nextTick(() => {
        setTimeout(() => {
          document.querySelector('body').classList.add('modal-open');

          this.fade = 'show'
        }, 500);
      })
    },

    hide: function() {
      this.fade = ''

      document.querySelector('body').classList.remove('modal-open');

      setTimeout(() => {
        this.display = 'none'

        this.template = ''
        this.title = ''
        this.style = {}
        this.props = {}
        this.args = {}
      }, 500)
    },

    setSize: function(w, h) {
      this.style.width = w
      this.style.height = h
      this.style.maxWidth = w
      this.style.maxHeight = h
    }
  }
}

</script>
