<template>
  <tr>
    <td v-if="isPending">{{name}}</td>
    <td v-else>
      <a
        class="document-type-csv"
        title="Download Report"
        target="_blank"
        rel="noreferrer nofollow"
        :href="url"
        :download="fileName"
      >
        {{name}}
      </a>
    </td>

    <td>{{reportType}}</td>

    <td>{{ h$.formatDate.short(createdAt) }}</td>

    <td v-if="isPending">
      <div class="spinner-border spinner-border-sm text-primary ms-4" role="status">
        <span class="visually-hidden">Running...</span>
      </div>
    </td>
    <td v-else>{{ h$.formatDate.short(date) }}</td>

    <td class="table-row-actions" :style="[isPending ? { 'opacity': 0 } : {}]">
      <RowAction
        v-if="!isPending"
        action="download"
        title="Download Report"
        target="_blank"
        rel="noreferrer nofollow"
        :href="url"
        :download="fileName"
      />

      <RowAction
        v-if="!isPending"
        href="#"
        action="unblock"
        title="Refresh Report"
        :graphql-action="true"
        @click.native.prevent="refreshReport"
      />

      <RowAction
        v-if="!isPending"
        href="#"
        action="delete"
        title="Delete Report"
        :graphql-action="true"
        @click.native.prevent="deleteReport"
      />
    </td>
  </tr>
</template>

<script>
import RowAction from "../shared/grid/row_action";
import Helpers from "../../helpers";

export default {
  components: {
    RowAction
  },

  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    reportType: {
      type: String,
      required: true
    },
    date: String,
    createdAt: String,
    fileName: String,
  },

  setup () {
    return {
      h$: Helpers
    }
  },

  computed: {
    url: function () {
      return "/downloadable_reports/" + this.id;
    },

    isPending: function () {
      return !this.date;
    },
  },

  methods: {
    refreshReport: function () {
      app.graphql.get(
        "update.downloadableReport.refresh",

        {
          input: {
            id: this.id
          }
        },

        (data) => {
          const response = data.data.refreshDownloadableReport
          if (response.errors) {
            response.errors.forEach((error) => {
              app.ui.toast.add({
                priority: "warning",
                title: "Unable to refresh report",
                message: error
              });
            });
          } else {
            app.ui.toast.add({
              priority: "success",
              title: "Report Refresh",
              message: "Report has been scheduled to refresh"
            });

            this.$emit("update-report", response.downloadableReport);
          }
        }
      );
    },

    deleteReport: function () {
      app.graphql.get(
        "delete.downloadableReport",

        {
          input: {
            id: this.id
          }
        },

        (data) => {
          const response = data.data.deleteDownloadableReport

          if (response.errors) {
            response.errors.forEach((error) => {
              app.ui.toast.add({
                priority: "warning",
                title: "Unable to delete report",
                message: error
              });
            });
          } else {
            app.ui.toast.add({
              priority: "success",
              title: "Report Deleted",
              message: "Report has been deleted"
            });

            this.$emit("delete-report", response.downloadableReport.id);
          }
        }
      )
    }
  }
}
</script>
