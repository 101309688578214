<template>
  <tr :class="{'is-marked': marked}">
    <td>
      <a
        :href="inspectionUrl"
        :title="name"
      >{{name}}</a>
      <div v-if="notes" class="note">{{notes}}</div>
    </td>

    <td>
      <a
        :href="projectUrl"
        :title="projectTitle"
      >{{project.name}}</a>
    </td>

    <td>
      <DetailsButton
        title="Site Details"
        entity-type="siteDetails"
        :entity="site.id"
      >
        <a
          :href="siteUrl"
        >{{site.name}}</a>
        <div v-if="site.description" class="note">{{site.description}}</div>
      </DetailsButton>
    </td>

    <td class="text-center">
      {{draftFindingsCount}}
    </td>

    <td class="text-center">
      <template v-if="policyUpdate && findingStatusCounts.review > 0">
        <CellAction
          action="approve"
          text="Approve"
          title="Approve all (In Review) findings"
          :label="findingsInReview"
          @clickAction="approveFindings"
        />
      </template>
      <template v-else>
        {{reviewFindingsCount}}
      </template>
    </td>

    <td class="text-center">
      {{approvedFindingsCount}}
    </td>

    <td class="text-end">
      {{ h$.formatDate.short(date) }}
    </td>

    <td class="table-row-actions">
      <RowAction
        :href="inspectionUrl"
        action="read"
      />

      <RowAction
        v-if="policyUpdate"
        :href="inspectionUrl + '/edit'"
        action="update"
      />

      <RowAction
        v-if="policyUpdate"
        :href="inspectionUrl"
        action="delete"
      />

      <RowAction
        v-if="policyUpdate"
        :href="findingsImportUrl"
        title="Import Findings"
        action="import"
      />
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import CellAction from "../shared/grid/cell_action";
import RowAction from "../shared/grid/row_action";
import DetailsButton from "../shared/grid/details_button";

import helpers from "../../helpers.js";

export default {
  extends: Row,
  
  components: {
    CellAction,
    RowAction,
    DetailsButton
  },

  props: {
    id: String,
    findingStatusCounts: Object,
    name: String,
    notes: String,
    date: String,
    project: Object,
    site: Object
  },

  setup () {
    return {
      h$: helpers
    }
  },

  computed: {
    inspectionUrl: function() {
      return "/inspections/" + this.id;
    },

    projectUrl: function() {
      return "/projects/" + this.project.id;
    },

    projectTitle: function() {
      return "Project: " + this.project.name
    },
    
    siteUrl: function() {
      return "/sites/" + this.site.id;
    },

    findingsImportUrl: function() {
      return "/finding_imports/new?project_id=" + this.project.id
    },

    findingsInReview: function() {
      return this.findingStatusCounts.review.toString();
    },

    reviewFindingsCount: function() {
      return (this.findingStatusCounts.review > 0) ? this.findingStatusCounts.review : "-";
    },
    
    approvedFindingsCount: function() {
      return (this.findingStatusCounts.approved > 0) ? this.findingStatusCounts.approved : "-";
    },

    draftFindingsCount: function() {
      return (this.findingStatusCounts.draft > 0) ? this.findingStatusCounts.draft : "-";
    }
  },

  methods: {
    approveFindings: function () {
      app.graphql.get(
        "update.inspection.approveFindings",

        {
          input: {
            inspectionId: this.id
          }
        },

        (data) => {
          const response = data.data.approveInspectionFindings;

          if (response.errors) {
            response.errors.forEach((error) => {
              app.ui.toast.add({
                priority: "warning",
                title: "Unable to update finding",
                message: error
              });
            })
          } else {
            app.ui.toast.add({
              priority: "success",
              title: "Inspection Findings",
              message: "All inspection findings from <strong>review</strong> status set to <strong>approved</strong>"
            })

            this.$emit("update-status", response.inspection);
          }
        }
      )
    }
  }
}
</script>