<template>
  <tr :class="{'is-marked': marked}">
    <td class="text-center">
      <DamageRating
        :rating="rating"
      />
    </td>

    <td class="text-center">
      <DamageRating
        :rating="finding.rating" 
      />
    </td>

    <td>
      <DetailsButton
        title="Finding Details"
        entity-type="findingDetails"
        :entity="finding.id"
      >
        <a
          :href="findingUrl"
          title="Finding"
        >{{finding.displayName}}</a>
        <div v-if="description" class="note">{{description}}</div>
      </DetailsButton>
    </td>

    <td>
      <a 
        :href="siteUrl" 
        title="Details"
      >
        {{finding.component.physicalAsset.site.name}}
      </a>
    </td>

    <td>
      <a 
        :href="assetUrl" 
        title="Details"
      >
        {{finding.component.physicalAsset.name}}
      </a>
    </td>

    <td>
      <a
        :href="componentUrl" 
        title="Details"
      >
        {{finding.component.name}}
      </a>
    </td>

    <td>
      {{user.name}}
    </td>

    <td class="text-end">
      {{ h$.formatDate.short(createdAt) }}
    </td>

    <td class="text-center">
      <span
        v-if="finding.photoCount > 0"
        class="indicator-images"
        title="Finding Photos" 
        data-action="show-details" 
        data-entity-type="findingPhotos"
        :data-entity="finding.id"
        :data-row="rowRef"
      ></span>
    </td>

    <td class="table-row-actions">
      <RowAction
        :href="findingUrl"
        action="read"
      />

      <RowAction
        v-if="policyUpdate"
        :href="findingUrl + '/edit'"
        action="update"
      />
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import RowAction from "../shared/grid/row_action";
import DetailsButton from "../shared/grid/details_button";
import DamageRating from "../shared/damage_rating";

import helpers from "../../helpers.js";

export default {
  extends: Row,

  components: {
    RowAction,
    DamageRating,
    DetailsButton
  },

  props: {
    id: String,
    rating: Number,
    finding: Object,
    createdAt: String,
    description: String,
    user: Object,
    projectId: String
  },

  setup () {
    return {
      h$: helpers
    }
  },

  computed: {
    findingUrl: function () {
      return "/findings/" + this.finding.id;
    },

    siteUrl: function () {
      return "/sites/" + this.finding.component.physicalAsset.site.id + '?v-filter={"projectId":["' + this.projectId + '"], "historical":true}';
    },

    assetUrl: function () {
      return "/physical_assets/" + this.finding.component.physicalAsset.id + '?v-filter={"projectId":["' + this.projectId + '"], "historical":true}';
    },

    componentUrl: function () {
      return "/components/" + this.finding.component.id + '?v-filter={"projectId":["' + this.projectId + '"], "historical":true}';
    }
  }
}
</script>