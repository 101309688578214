<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }" style="min-height: 200px;">
    <div v-bind:class="{'hidden': !isLoaded }"></div>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <fieldset>
            <legend>Report type</legend>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="placeholder placeholder-inactive" for="report_name">Report name</label>
                  <input
                    :class="['form-control', {'error': v$.reportName.$error}]"
                    placeholder="Name"
                    type="text"
                    name="report[name]"
                    id="report_name"
                    v-model.trim="reportName"
                    @keydown.enter.prevent="submitForm"
                  />
                  <div v-if="v$.reportName.$error" class="error">Report name is required</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="placeholder placeholder-inactive">Report Type</label>
                  <v-select
                    placeholder="Select report type"
                    ref="reportTypes"
                    :class="[{'vs-error': v$.reportType.$error}]"
                    :options="reportTypes"
                    label="name"
                    @update:modelValue="setReportType"
                  />
                  <div v-if="v$.reportType.$error" class="error">Report type is required</div>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset v-if="reportType">
            <legend>Report Parameters</legend>
            <component
              :is="reportType"
              ref="reportParameters"
              v-model:parameters="arguments"
              @update:parameters="updateArguments"
              @keydown.enter.prevent="submitForm"
            ></component>
          </fieldset>
        </div>
      </div>
      <div class="form-group">
        <form-button
          ref="submitButton"
          :label="submitButtonLabel"
          @submit="submitForm"
        ></form-button>
      </div>
    </form>
  </div>
</template>

<script>
import vSelect from "vue-select";

import formButton from "../shared/form/form_button"
import CurrentComponentStatusCSV from "./report_arguments/current_component_status"
import InspectedAssetsCSV from "./report_arguments/inspected_assets"

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  components: {
    vSelect,
    formButton,
    CurrentComponentStatusCSV,
    InspectedAssetsCSV,
  },

  props: {
    report: Object,
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data () {
    return {
      isLoaded: false,
      isSpinnerVisible: true,
      reportName: '',
      reportType: '',
      arguments: {},
      reportTypes: [
        {name: 'Current Component Status', value: 'CurrentComponentStatusCSV'},
        {name: 'Inspected Assets + Remediations', value: 'InspectedAssetsCSV'}
      ]
    }
  },

  validations () {
    return {
      reportName: {
        required
      },
      reportType: {
        required
      }
    }
  },

  mounted: function() {
    this.isLoaded = true;

    setTimeout(() => {
      this.isSpinnerVisible = false;
    }, 1000);
  },

  computed: {
    submitButtonLabel: function () {
      return this.report.id ? "Update Report" : "Create Report"
    },
  },

  methods: {
    setReportType: function(opt) {
      this.reportType = (opt) ? opt.value : '';

      this.arguments = {};
    },

    updateArguments: function(args) {
      this.arguments = args;
    },

    submitForm: function() {
      this.v$.reportName.$touch();
      this.v$.reportType.$touch();

      if (this.$refs.reportParameters.v$) {
        this.$refs.reportParameters.v$.$touch();
      }

      if (!this.v$.$error) {
        app.graphql.get(
          "create.downloadableReport",

          {
            input: {
              name: this.reportName,
              reportType: this.reportType,
              arguments: JSON.stringify(this.arguments),
            }
          },

          (data) => {
            const response = data.data.createDownloadableReport

            if (response.errors) {
              response.errors.forEach((error) => {
                app.ui.toast.add({
                  priority: "warning",
                  title: "Unable to create report",
                  message: error,
                })
              })
            } else {
              window.location.href = "/downloadable_reports"
            }
          }
        )
      }
    },
  },
}
</script>
