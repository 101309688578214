<template>
  <div class="table-wrapper">
    <div class="row">
      <div class="col-md-6 col-sm-8 grid-search-control">
        <SearchField
          v-model:textSearch="textSearch"
          :placeholder="textSearchPlaceholder"
          @input="isSpinnerVisible = true"
          @search="search"
        />
      </div>
    </div>

    <div
      :class="['table-responsive', { 'spinner-is-visible': isSpinnerVisible }]"
    >
      <table class="table">
        <thead>
          <tr>
            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'NAME'"
              @sort="reorder"
            >Asset</SortableHeader>

            <SortableHeader
              class="text-center"
              style="width: 200px"
              :current-sort="orderBy"
              :sort-by="worstCaseDamageColumnSortBy"
              @sort="reorder"
            >
              <InfoBadge
                content="<strong>Worse Case Damage:</strong> This value is calculated for each component of an asset based on the worst rating among the approved findings for the most recent project for that component. The overall asset rating is then calculated based on the worst rating among those components.<br><br>Note: If one (or more) projects are included in the filter, the calculation is the same but the ratings will only be based on those filtered projects."
              />
              Worst Case Damage
            </SortableHeader>

            <SortableHeader
              v-if="hasPoleNumberDetail"
              class="text-center"
              style="width: 200px"
              :current-sort="orderBy"
              :sort-by="'POLE_NUMBER_DETAIL'"
              @sort="reorder"
            >Pole Number</SortableHeader>

            <SortableHeader
              v-if="showLeCondition"
              class="text-center"
              style="width: 200px"
              :current-sort="orderBy"
              :sort-by="leadingEdgeWorstCaseDamageColumnSortBy"
              @sort="reorder"
            >Leading Edge Condition</SortableHeader>

            <SortableHeader
              class="text-center"
              style="width: 150px"
              :current-sort="orderBy"
              :sort-by="'QR_LABEL'"
              @sort="reorder"
            >QR Label</SortableHeader>

            <SortableHeader
              class="text-end"
              style="width: 150px"
              :current-sort="orderBy"
              :sort-by="activityDateColumnSortBy"
              @sort="reorder"
            >{{ activityTitle }}</SortableHeader>

            <th
              class="text-end"
              style="width: 150px"
            >Replacement Date</th>

            <th data-sorter="false"></th>
          </tr>
        </thead>

        <tbody>
          <PhysicalAsset
            v-for="(item, index) in items"
            v-bind="item"
            :key="item.id"
            :ref="item.id"
            :filter="filter"
            :index="index"
            :policy-update="policyUpdate"
            :is-project-filter="isProjectFilter"
            :show-in-preview-mode="isFilterInPrevievMode"
            :show-le-condition="showLeCondition"
            :show-pole-number="hasPoleNumberDetail"
            :selected="isItemMarked(item.id)"
          />
        </tbody>
      </table>

      <div class="spinner"></div>

      <NoRecords
        label="According to the search criteria, there are no physical assets found."
      />

      <Pagination
        v-bind="pageInfo"
        entity-type="assets"
        @navigate-to="paginate"
      />
    </div>
  </div>
</template>

<script>
import Grid from "../shared/grid/grid";

import PhysicalAsset from "./physical_asset";
import InfoBadge from "../shared/info_badge";

export default {
  extends: Grid,

  components: {
    PhysicalAsset,
    InfoBadge
  },

  props: {
    siteId: String,
    showLeCondition: Boolean
  },

  data: function () {
    return {
      items: [],

      grid: "site-physical-assets",

      textSearch: '',

      defaultOrderBy: {
        field: "NAME",
        direction: "ASC"
      },

      orderBy: {
        field: "NAME",
        direction: "ASC"
      },

      pageInfo: {
        pageSize: 20,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true,
      
      isPoleNumberDetailDetected: false,
      hasPoleNumberDetail: false
    }
  },

  mounted: function() {
    
  },

  watch: {
    filter: function() {    
      if (["APPROVED_ACTIVITY_DATE", "PREVIEW_ACTIVITY_DATE"].includes(this.orderBy.field)) {
        this.orderBy.field = this.activityDateColumnSortBy;
      }

      if (["CLIENT_REMEDIATED_RATING", "REMEDIATION_PREVIEW_RATING"].includes(this.orderBy.field)) {
        this.orderBy.field = this.worstCaseDamageColumnSortBy;
      }

      if (["LEADING_EDGE_CLIENT_REMEDIATED_RATING", "LEADING_EDGE_REMEDIATION_PREVIEW_RATING"].includes(this.orderBy.field)) {
        this.orderBy.field = this.leadingEdgeWorstCaseDamageColumnSortBy;
      }

      if (this.isProjectFilter) {
        this.orderBy.projectId = this.filter.projectId[0];
      } else {
        delete this.orderBy["projectId"];
      }
    },
  },

  computed: {
    // grid.js overriden method for more complex sorting
    queryOrder: function () {
      if (this.isProjectFilter) {
        this.orderBy.projectId = this.filter.projectId[0];
      } else {
        delete this.orderBy["projectId"];
      }

      return this.orderBy;
    },
    
    activityTitle: function () {
      return this.isProjectFilter ? "Activity Date" : "Last Activity";
    },

    activityDateColumnSortBy: function() {
      return this.isFilterInPrevievMode ? "PREVIEW_ACTIVITY_DATE" : "APPROVED_ACTIVITY_DATE";
    },

    worstCaseDamageColumnSortBy: function() {
      return this.isFilterInPrevievMode ? "REMEDIATION_PREVIEW_RATING" : "CLIENT_REMEDIATED_RATING";
    },

    leadingEdgeWorstCaseDamageColumnSortBy: function() {
      return this.isFilterInPrevievMode ? "LEADING_EDGE_REMEDIATION_PREVIEW_RATING" : "LEADING_EDGE_CLIENT_REMEDIATED_RATING";
    },

    textSearchFields: function () {
      return this.hasPoleNumberDetail ? ["NAME", "DESCRIPTION", "QR_LABEL", "POLE_NUMBER_DETAIL"] : ["NAME", "DESCRIPTION", "QR_LABEL"];
    },

    textSearchPlaceholder: function () {
      return this.hasPoleNumberDetail ? "Search for Assets by Name, Description, QR Label or Pole Number" : "Search for Assets by Name, Description or QR Label";
    },

    isFilterEmpty: function() {
      return (Object.keys(this.filter).filter(x => !["siteId", "historical", "status"].includes(x))).length === 0 && this.filter.historical == false;
    },

    isFilterInHistoricalMode: function() {
      return this.filter && this.filter.historical && this.filter.historical === true;
    },

    isFilterInPrevievMode: function() {
      return this.filter.status ? ["approved", "draft", "review"].every((val) => this.filter.status.includes(val)) : false;
    },

    isProjectFilter: function () {
      return (this.filter.projectId != undefined && this.filter.projectId.length == 1);
    },

    filterParams: function () {
      let params = {
        siteId: this.siteId
      };

      if (!this.isFilterEmpty || this.isFilterInHistoricalMode || this.isFilterInPrevievMode) {
        params.findings = this.filter;

        params.findings.siteId = this.siteId;
        params.findings.historical = this.filter.historical || false;
      };

      if (this.textSearch) {
        params.text = {
          value: this.textSearch,
          fields: this.textSearchFields
        };
      };

      return params;
    },

    queryName: function () {
      if (this.isProjectFilter) {
        return "grid.site.projectAssets";
      } else {
        return "grid.site.assets";
      }
    }
  },

  methods: {
    fetch: function (params, success) {
      app.graphql.get(this.queryName,
        this.queryParams(params),

        (data) => {
          let physical_assets = data.data.physicalAssets,
              pageInfo = physical_assets.pageInfo;

          if (physical_assets.totalCount > 0 && !this.isPoleNumberDetailDetected) {
            this.hasPoleNumberDetail = physical_assets.hasPoleNumbers;

            this.isPoleNumberDetailDetected = true;
          }

          success(physical_assets, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch physical assets!"
          })
        }
      );
    },

    queryParams: function (params) {
      if (this.isProjectFilter) {
        params.projectId = this.filter.projectId[0];
      }

      return Object.assign({
        includeLECondition: this.showLeCondition,
      }, params);
    },

    onGetItems: function (items) {
      if (window.vmSiteMap && window.vmSiteMap.$refs && window.vmSiteMap.$refs.siteMap) {
        vmSiteMap.$refs.siteMap.setAssets(items, this.pageInfo);
      }
    }
  }
}
</script>
