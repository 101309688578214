<template>
  <tr :class="{'is-marked': marked}">
    <td>
      <a 
        :href="physicalAssetUrl"
        title="Asset details"
      >{{ component.physicalAsset.displayName }}</a>
    </td>

    <td>
      <a 
        :href="componentUrl"
        title="Component details"
      >{{ component.displayName }}</a>
    </td>

    <td>
      <a 
        :href="inspectionUrl"
        title="Inspection details"
      >{{ inspection.displayName }}</a>
    </td>

    <td class="text-end">{{ h$.formatDate.short(inspection.date) }}</td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import RowAction from "../shared/grid/row_action";
import DetailsButton from "../shared/grid/details_button";
import DamageRating from "../shared/damage_rating";

import helpers from "../../helpers.js";

export default {
  extends: Row,

  components: {
    RowAction,
    DamageRating,
    DetailsButton
  },

  props: {
    id: String,
    component: Object,
    inspection: Object,
    createdAt: Object,
  },

  setup () {
    return {
      h$: helpers
    }
  },

  computed: {
    replacementUrl: function () {
      return "/replacements/" + this.id
    },

    inspectionUrl: function () {
      return "/inspections/" + this.inspection.id
    },

    componentUrl: function () {
      return "/components/" + this.component.id
    },
    
    physicalAssetUrl: function () {
      return "/physical_assets/" + this.component.physicalAsset.id
    },

    inspectionUrl: function () {
      return "/inspections/" + this.inspection.id
    }
  }
}
</script>
